@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@font-face {
    font-family: "Amalfi Coast";
    src: url("../../assets/fonts/AmalfiCoast.ttf");
}
@font-face {
	font-family:'Avenir Next';
	src:	url('../../assets/fonts/AvenirNextLTPro-It.otf');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family:'Avenir Next';
	src:	url('../../assets/fonts/AvenirNextLTPro-Regular.otf');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family:'Avenir Next';
	src:	url('../../assets/fonts/AvenirNextLTPro-Bold.otf');
	font-weight: bold;
	font-style: normal;
}
@font-face {
    font-family: "Baskerville";
    src: url("../../assets/fonts/Baskerville.ttc");
}
@font-face {
    font-family: 'LFT Etica';
    src: local('LFT Etica Regular'), local('LFT-Etica-Regular'),
        url('../../assets/fonts/LFTEticaBk.woff2') format('woff2'),
        url('../../assets/fonts/LFTEticaBk.woff') format('woff'),
        url('../../assets/fonts/LFTEticaBk.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'LFT Etica';
    src: local('LFT Etica Regular'), local('LFT-Etica-Regular'),
        url('../../assets/fonts/LFTEtica.woff2') format('woff2'),
        url('../../assets/fonts/LFTEtica.woff') format('woff'),
        url('../../assets/fonts/LFTEtica.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'LFT Etica';
    src: local('LFT Etica Bold'), local('LFT-Etica-Bold'),
        url('../../assets/fonts/LFTEtica-Bold.woff2') format('woff2'),
        url('../../assets/fonts/LFTEtica-Bold.woff') format('woff'),
        url('../../assets/fonts/LFTEtica-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'LFT Etica';
    src: local('LFT Etica  Extra Bold'), local('LFT-Etica--Extra-Bold'),
        url('../../assets/fonts/LFTEticaXB.woff2') format('woff2'),
        url('../../assets/fonts/LFTEticaXB.woff') format('woff'),
        url('../../assets/fonts/LFTEticaXB.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url('../../assets/fonts/Chance-Light.eot');
	src:	url('../../assets/fonts/Chance-Light.eot?#iefix') format('embedded-opentype'),
			url('../../assets/fonts/Chance-Light.woff2') format('woff2'),
			url('../../assets/fonts/Chance-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url('../../assets/fonts/Chance-Regular.eot');
	src:	url('../../assets/fonts/Chance-Regular.eot?#iefix') format('embedded-opentype'),
			url('../../assets/fonts/Chance-Regular.woff2') format('woff2'),
			url('../../assets/fonts/Chance-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url('../../assets/fonts/Chance-Medium.woff2') format('woff2'),
			url('../../assets/fonts/Chance-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family:'Chance';
	src:	url('../../assets/fonts/Chance-Bold.eot');
	src:	url('../../assets/fonts/Chance-Bold.eot?#iefix') format('embedded-opentype'),
			url('../../assets/fonts/Chance-Bold.woff2') format('woff2'),
			url('../../assets/fonts/Chance-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
    font-family: 'CaslonGraphiqueEF';
    src: url('../../assets/fonts/CaslonGraphiqueEF.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BigCaslonFB';
    src: url('../../assets/fonts/BigCaslonFB-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'BigCaslonFB';
    src: url('../../assets/fonts/BigCaslonFB-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Baihgale';
    src: url('../../assets/fonts/Baihgale-9YVLy.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BebasNeue';
    src: url('../../assets/fonts/BebasNeue.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CradleyTitle-Regular2';
    src: url('../../assets/fonts/CradleyTitle-Regular2.otf');
    font-weight: normal;
    font-style: normal;
}
/** scrollbar **/
::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border: 0px none #ffffff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
/** end scrollbar **/
.App {
    font-family: 'Chance', sans-serif;
    display: flex;
    .absolute-edit{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height: 100%;
    }
    .front .iframe-placeholder{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #dedede;
        padding: 0 32px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        img{
            max-width: 120px;
        }
        p{
            font-size: 14px;
        }
    }
    #mobile-view{
        .front .iframe-placeholder{
            img{
                max-width: 60px;
            }
            h4{
                margin-bottom: .5em;
                font-size: 16px;
            }
            p{
                font-size: 12px;
                margin: 0;
            }
        }
    }
    .templates{
        position: relative;
        width: 100%;
        min-height:100vh;
        background-color: #21409a;
        overflow: hidden;
        &:after{
            content: '';
            position: absolute;
            background-color: rgba(0,0,0,.2);
            padding-bottom: 141.42136%;
            width: 100%;
            bottom: 0;
            left: 0;
            transform: rotate(65.5deg) ;
            transform-origin: left bottom;
        }
        .container{
            position: relative;
            z-index: 2;
            .ttr{
                color: #fff;
                text-align: center;
                margin-top: 48px;
            }
            .slick-arrow:before{
                color:#ef0909;
            }
            .slick-prev{
                z-index: 2;
            }
            .spinner-container{
                min-height: calc(80vh - 2rem);
            }
            .card{
                list-style: none;
                position: relative;
                max-width: 300px;
                background-color: transparent;
                text-align: left;
                margin-bottom: 16px;
                min-height: 200px;
                border: 5px solid transparent;
                overflow: hidden;
                margin-left: auto;
                margin-right: auto;
                .card-background{
                    max-height: 450px;
                    overflow-y: scroll;
                    margin: -5px;
                    @media(max-width:1199px){
                        max-height: 350px;
                    }
                    @media(max-width:575px){
                        max-height: 450px;
                    }
                    img{
                        width: 100%;
                        height: auto;
                        position: relative;
                        transform: scale(1) translateZ(0);
                        transition: 
                        transform 200ms linear;
                    }
                }
                .card-content{
                    left: 0;
                    padding: 1rem;
                    position: absolute;
                    top: 0;
                }
                .card-heading{
                    color: #fff;
                    font-size: 15px;
                    text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
                    line-height: 1.2;
                    background-color: #01b9ff;
                    padding: 5px;
                    width: 100%;
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
                }
                .button-action{
                    position: absolute;
                    display: flex;
                    bottom: -100%;
                    width: 100%;
                    left: 0;
                    padding: 8px 16px;
                    justify-content: space-between;
                    transition: all .4s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
                &:hover{ 
                    border-color:#ef0909;
                    .card-background img{
                        transform: scale(1.05) translateZ(0);
                    }
                    .button-action{
                        bottom: 16px;
                    }
                    .card-heading{
                        opacity: .2;
                    }
                }
            }
            .slick-list{
                overflow: visible;
            }
        }
    }
    .front .engagement .section.faq .text-content{
        height: auto;
        top: 50%;
        transform: translateY(-50%);
    }
    .login-box {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        max-width: 1000px;
        background-color: white;
        box-shadow: 0 0 40px rgba(0,0,0,0.16);
        overflow: hidden;
        margin: 0 auto;
        border-radius: 12px;
    }
    #login-form {
        flex: 1 0 100%;
        max-width: 480px;
        width: 100%;
        padding:50px 60px;
        box-sizing: border-box;
    }
    #login-form p {
        color: rgb(33, 64, 154);
        margin-bottom: 30px;
    }
    #login-form p.form-title {
        font-size: 39px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 0;
    }
    #login-form p.error {
        font-size: 10px;
        font-weight: 500;
        color:#ef0909
    }
    .illustration-wrapper {
        display: flex;
        align-items: flex-end;
        max-width: 800px;
        min-height: 100%;
        background-color: #fffdf2;
    }
    .illustration-wrapper img {
        display: block;
        width: 101%;
        margin-top: -2px;
    }
    @media screen and (max-width: 1023px) {
        .login-box {
            flex-direction: column;
            box-shadow: none;
        }
        .illustration-wrapper {
            max-width: 100%;
            min-height: auto;
        }
        #login-form {
            max-width: 100%;
        }
    }
    .input-edit,.textarea-edit{
        background: transparent;
        display: inline-block;
        border: 1px solid transparent;
        padding: 0;
        width: 100%;
        display: block;
        line-height: 1;
        caret-color:red;
        overflow-y: hidden;
        &:hover{
            border-color:red;
        }
    }
    .textarea-edit{
        height: auto;
    }
    .label-edit{
        height: 100%;
        width: 100%;
        display: block;
        &:hover{
            border: 1px solid red!important;
        }
    }
}
.SideBar{
    width:300px;
    position: fixed;
    left: 0;
    background-color: #21409a;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    min-height: 100vh;
    &.expand{
        .expand{
            right: -40px;
            background-color: #21409a;
            padding: 4px 12px;
        }
        @media(max-width:767px){
            left: -100%;
        }
    }
    @media(max-width:767px){
        width: 100%;
        z-index: 2;
        transition: all .2s ease;
    }
    .expand{
        display: none;
        border: none;
        background-color: transparent;
        padding: 0;
        color: #fff;
        font-size: 20px;
        position: absolute;
        right: 16px;
        top: 16px;
        @media(max-width:767px){
            display: block;
        }
    }
    h2{
        text-align: center;
        margin-top:24px;
    }
    .scroll{
        max-height: calc(100vh - 100px);
        overflow-y: scroll;
        margin: 14px;
        margin-right: 1px;
        padding-right: 14px;
        ul{
            padding: 0;
            .edit-content{
                padding: 16px 0;
                margin-bottom: 16px;
                border-bottom: 1px solid #fff;
            }
        }
        .lite-wysiwyg{
            button{
                margin-right: 2px;
                &.active{
                    background-color: #fefefe;
                    color: #232323;
                }
            }
            .editor{
                background-color: #fefefe;
                border: none;
                padding: .5rem .75rem;
                min-height: 100px;
                &:focus{
                    outline: 0;
                    cursor: text;
                    caret-color: red;
                    &::selection {
                        background:#ef0909;
                        color: #FFFFFF;
                    }
                }
            }
            .link-about{
                border:1px solid #fff;
                padding:16px 10px;
                border-top: 0;
            }
        }
        .object{
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            color:#f6f6f6;
            margin-bottom: 12px;
            justify-content: space-between;
            align-items: center;
            background-color: #01b9ff;
            font-size: 14px;
            padding-left: 16px;
        }
    }
}
.Content{
    margin-left: 300px;
    width:calc(100% - 300px);
    display: block;
    padding: 1rem;
    position: relative;
    @media(max-width:767px){
        margin-left: 0;
        width: 100%;
    }
    .front{
        border:2px dashed #01b9ff;
    }
    &#mobile-view{
        .front{
            width:340px;
            height:598px;
            margin: auto;
            margin-top:76px;
            overflow: hidden;
            overflow-y: scroll;
            border:none;
            position: relative;
        }
        &:before{
            content:"";
            position:fixed;
            top:0;
            left: calc((50% - 170px) + 123px);
            width: 390px;
            height: 760px;
            background-image: url(../img/phone.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &#tablet-view{
        .front{
            background-color: #fff;
            width:768px;
            height:576px;
            margin: auto;
            margin-top: 25px;
            overflow: hidden;
            overflow-y: scroll;
            border:none;
            position: relative;
        }
        &:before{
            content:"";
            position:fixed;
            top:0;
            left: calc((50% - 460px) + 111px);
            width: 984px;
            height: 665px;
            background-image: url(../img/tablette.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .form-group label{
        text-align: justify;
        max-width: 350px;
        display: block;
        line-height: 1.5;
    }
}
.border-bottom-white{
    border-bottom: 1px solid #d3d3d3;
}
.mb-1{
    margin-bottom: 1rem;
}
.mt-1{
    margin-top: 1rem;
}
.SideBar .scroll{
    .marge{
        display: flex;
        justify-content: space-around;
    }
    .block{
        padding:0 12px 1rem;
        margin-bottom: 1rem;
        &.hide{
            display: none;
        }
        .select-dropdown i{
            font-size: 12px!important;
        }
        .style,.popup .style{
            display: flex;
            justify-content: space-between;
        }
        .style .color .color-selector{
            padding-right: 15px;
        }
        .style.deux .color,.style.deux .size{
            width: 50%;
        }
        .style .weight .select-dropdown{
            width: 100%;
        }
        .style .weight .select-dropdown ul{
            z-index: 12!important;
        } 
        .style .align{
            width: 33.33%;
        }
    }
} 
.popup .style{
    margin-bottom: 16px;
}
.popup .style .color{
    width: 50%;
}
i.fa-info-circle{
    cursor: pointer;
    position: relative;
    &[data-tooltip]::after {
        content: attr(data-tooltip);
        width: 160px;
        font-size: 13px;
        position: absolute;
        font-family:'Chance', sans-serif;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 4px 8px;
        border-radius: 4px;
        color: white;
        transform: scale(0);
        transition: 0.2s ease-in-out;
    }
    &[data-tooltip]:hover::after {
        transform: scale(1);
        bottom: 18px;
        left: 0;
    }
}
.style .size i[data-tooltip]:hover::after,.popup-body form p i[data-tooltip]:hover::after{
    left:-85px
}
.class i[data-tooltip]:hover::after{
    left:-200px;
    width:250px;
    bottom: -128px;
    z-index: 11;
}
.popup-body form p i[data-tooltip]::after{
    line-height: 18px;
    width:440px;
    white-space: break-spaces;
}
.popup-body form p i[data-tooltip]:hover::after{
    bottom: -120px;
}
/** radio button **/
.App input[type="radio"]{
    display: none;
  }
.App input[type="radio"] + label{
    position: relative;
    display: inline-block;
    color: #f6f6f6;
    padding-left: 1.5em;
    cursor: pointer;
    line-height: 1em;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.App input[type="radio"] + label:before,
.App input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.App input[type="radio"] + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}
.App input[type="radio"] + label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.App input[type="radio"]:checked + label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #ef0909;
}
/* end radio */
.SideBar{
    .react-datetime-picker{
        width: 100%;
        button{
            font-family: "Chance", sans-serif;
            font-weight: 500;
            font-size: 13px;
        }
        .react-datetime-picker__wrapper{
            border: none;
            background-color: #01b9ff;
            padding-left: 10px;
        }
        .react-calendar{
            border: 1px solid #fefefe;
            box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: .3);
            .react-calendar__navigation{
                background-color: #ef0909;
                button{
                    color: #fff;
                }
            }
            .react-calendar__month-view__weekdays__weekday{
                abbr[title]{
                    color: #232323;
                    text-decoration: none;
                    font-weight: 700;
                    font-family: "Chance", sans-serif;
                }
            }
            .react-calendar__year-view{
                .react-calendar__tile{
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    &:hover,&:focus{
                        border-radius: 50%;
                    }
                }
                .react-calendar__tile--hasActive{
                    background-color: #ef0909;
                    color: #fff;
                }
            }
        }
        .react-datetime-picker__button{
            background-color: #ef0909;
            padding: 8px 10px;
            margin-left: 2px;
            svg{
                stroke: #fff;
            }
        }
        .react-calendar__tile--active{
            background-color: #ef0909;
            border-radius: 50%;
        }
        .react-calendar__tile--active:hover{
            background-color: #01b9ff;
        }
    }
    .scroll .row .style.three{
        align-items: flex-end;
        .size{
            width: 25%;
        }
    } 
    h1,p,h2,h4,h3,h5{
        color:#f6f6f6;
    }
    h4{
        font-size: 15px;
        font-weight: 500;
    }
    h5{
        font-size: 13px;
        font-weight: 500;
        margin-top: 16px;
    }
    p{
        font-size:13px;
        margin-top: 0;
    }
}
input,textarea{
    &:focus{
        outline: 0;
    }
}
.SideBar{
    .color-selector {
        display: inline-block;
        margin: 0 auto;
        position: relative;
        font-size: 14px;
        padding: .52rem .45rem;
        background: #01b9ff;
        color:#fff;
        width: 100%;
        &::after{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: #fff transparent transparent transparent;
            position: absolute;
            right: 5px;
            top: 17px;
        }
        .circle {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #eee;
            margin-right: 5px;
        }  
        .hidden {
            position: absolute;
            left: 0;
            opacity: 0;
        }  
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }
}
/** input number **/
.SideBar .input-number {
    position: relative;
    display: flex;
    width: 50px;
    height: 40px;
    background-color: transparent;
    overflow: hidden;
    margin: 0;
  }
.SideBar .input-number .number {
    background-color: #01b9ff;
    border: none;
    height: 100%;
    width: 30px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #fff;
}
.SideBar .input-number .action{
    display: flex;
    flex-direction: column;
}
.SideBar .input-number button {
    display: inline-block;
    width: 22px;
    height:20px;
    background-color: #ef0909;
    border: none;
    color: white;
    font-size: 18px;
    line-height: 6px;
    cursor: pointer;
    transition: background-color .2s ease;
}
.SideBar .input-number button:nth-of-type(1) {
    border-bottom: 1px solid rgba(0,0,0,.2);
}
/** end input number **/
/** checkbox **/
.form-group{
    margin-bottom: 16px;
}
.SideBar .form-group{
    text-align: left;
}
.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
} 
.form-group label {
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}
.form-group label .square {
    -webkit-appearance: none;
    background-color: #ef0909;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}
.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 6px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
/** end checkbox **/
.dropdown{
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    background-color: white;
    text-align: left;
    margin-bottom: 16px;
}
.dropdown-header {
    padding: 15px!important;
    cursor: pointer;
    display: flex!important;
    justify-content: space-between;
    align-items: center;
}
.dropdown-body {
    padding: 5px;
    border: 1px solid #E5E8EC;
    display: none;
    background-color: #21409a;
    border-bottom-left-radius:10px ;
    border-bottom-right-radius:10px ;
}
.dropdown-body.open {
    display: block;
}
.dropdown-body h3{
    font-size: 18px;
    margin: 16px 0;
}
.dropdown-body h4{
    font-size: 15px;
    margin: 16px 0;
    small{
        font-size: 11px;
        font-weight: 400;
    }
}
.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
} 
.icon.open {
    transform: rotate(90deg);
}
.image-container{
    position: relative;
    button{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
    .custom-file-upload{
        display: block;
        cursor: pointer;
        height: 150px;
        .img-wrap{
            display: block;
            img{
                width: 100%;
                height: 150px;
                object-fit: contain;
                background-color: #01b9ff;
                box-shadow: 4px 4px rgba(0,0,0,.4);
            }
        }
        input[type="file"]{
            height: 0;
            width: 0;
        }
    }
}
/** input range **/

.range{
    text-align: center;
    padding: 16px 0;
    label{
        color:#fff;
        display: block;
    }
    input[type=range] {
        width: 190px;
        -ms-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        cursor: pointer;
        /*extra*/
        display: block;
        margin: 10px auto 0 auto;
        position: relative;
    }
    input[type=range]::-webkit-slider-runnable-track {
        background: #fff;
        border-radius: 3px;
        height: 5px;
        -webkit-appearance: none;
    }
    input[type=range]::-webkit-slider-thumb {
        border: none;
        box-shadow: none;
        height: 20px;
        width: 6px;
        border-radius: 12px;
        background: #ef0909;
        -webkit-appearance: none;
        margin: -7px 0 0 0;
        outline: none;
    }
}
/** popup **/
.popup-body {
    .element-choice{
        button{
            font-size: 13px;
            border-radius: 2px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            i{
                font-size: 28px;
                margin-bottom: 10px;
            }
        } 
    }
    canvas{
        display: block;
        margin: 0 auto;
    }
    .custom-file-upload {
        .img-wrap{
            text-align: center;
            img{
                width: 150px;
                border:4px solid #ef0909;
            }
        }
    }
    .html-content{
        max-height: 60vh;
        color:#fff;
        overflow-y: scroll;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 16px;
        text-align: justify;
        word-break: break-all;
    }
    .progress-content{
        position: relative;
        width: 250px;
        height: 5px;
        background-color: rgba(255,255,255, .8);
        border-radius: 5px;
        overflow: hidden;
        margin: 0 auto;
        .progress-bar{
            height: 100%;
            background-color: #ef0909;
        }
    }
}
/** Custom select **/
.select-dropdown {
    color: #f6f6f6;
    position: relative;
    width: 100%;
    display: inline-block
}
.select-dropdown ul {
    max-height: 0;
    transition: max-height 0.5s;
    border: 2px solid #EEEEEE;
    border-bottom: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
    list-style: none;
    margin-top: 3px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 11;
    &.show{
        max-height: unset;
        color: #6f6f6f;
        overflow: visible;
        min-height: 100%;
    }
    li:hover {
        background: #EFEFEF;
    }
}
/* en custom select */
/** style popup **/
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    &.large{
        .popup_inner {
            width:900px;
            left:50%;
            transform: translateX(-50%);
            height: 600px;
        }
    }
    .popup_inner {
        position: absolute;
        left: calc(25% + 300px);
        right: 25%;
        margin: auto;
        background: #21409a;
        width: 450px;
        &.full{
            left: calc(50% - 225px);
            @media(max-width:767px){
                left: 0;
                width: 100%;
            }
        }
        .popup-header{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d3d3d3;
            padding-left: 16px;
            h4{
                margin: 10px 0;
                font-size: 18px;
            }
        }
        @media(max-width:767px){
            left: 0;
            width: 100%;
        }
        .popup-body{
            padding:16px
        }
        p,h4{
            color:#fff
        }
        p{
            font-size: 14px;
            &.success{
                color: #42ba96;
            }
            &.error{
                color:#FF9494;
            }
            &.success,&.error{
                font-size: 12px;
                font-weight: 500;
                margin: -15px 0 15px;
            }
        }
    }
}
.connexion-form input{
    display: inline-block;
}
/** sidenav **/
#mySidenav{
    position: fixed;
    right: 0;
    top: 10%;
    z-index:10000
}
#mySidenav a,#mySidenav button {
    right: -60px;
    color: white;
    width: 130px;
    padding: 9px 6px 9px 15px;
    position: absolute;
    transition: 0.3s;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px 0 0 5px;
    box-shadow: 2px 2px rgba(0,0,0,.2);
    border: none;
    text-align: left;
    @media(max-width:767px){
        right: -94px;
    }
}
#mySidenav a:hover,#mySidenav a.active,#mySidenav button:hover,#mySidenav button.active {
    right:0;
}
#import {
    top: 65px;
    background-color: #01b9ff;
}
#back {
    top: 200px;
    background-color: #ef0909;
}
#voir {
    top: 110px;
    background-color: green;
}
#cancel {
    top: 155px;
    background-color: #71c341;
}
#refresh {
    top: 20px;
    background-color: #e9950c;
}
#mobile {
    top: 290px;
    background-color:#21409a;
    &.special{
        top: 335px;
    }
    @media(max-width:767px){
        display: none;
    }
}
#desktop {
    top: 290px;
    background-color: #ef0909;
}
#tablet {
    top: 335px;
    background-color: #232323;
    @media(max-width:767px){
        display: none;
    }
}
/** login page **/
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    background-color: #21409a;
    overflow: hidden;
    &:after{
        content: '';
        position: absolute;
        background-color: rgba(0,0,0,.2);
        padding-bottom: 141.42136%;
        width: 100%;
        bottom: 0;
        left: 0;
        transform: rotate(65.5deg) ;
        transform-origin: left bottom;
    }
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.spinner-container{
    position: absolute;
    width: 100%;
    min-height: calc(100vh - 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    .loading-spinner {
        width: 50px;
        height: 50px;
        border: 10px solid #f3f3f3; /* Light grey */
        border-top: 10px solid #383636; /* Black */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }
}