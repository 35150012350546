$poppins: 'Poppins', sans-serif;
$lftE: 'LFT Etica', sans-serif;
$amalfi: 'Amalfi Coast', sans-serif;
$primary: #28427d;
$secondary: #f0592d;
$pink:#ec6a8e;
.front{
    font-family: $lftE;
    max-width: 100%;
    width: 100%;
    .section{
        position: relative;
        .jumbotron{
            background-size: cover;
            background-position: center;
            display: block;
            text-decoration: none;
            border-radius: 0;
            margin-bottom: 0;
            padding:4rem 0;
            .ttr{ 
                &,& .input-edit{
                    text-transform: uppercase;
                    margin-bottom: 120px;
                    @media (max-width: 992px){
                        line-height: 1;
                    }
                }
                span{
                    &,& .input-edit{
                        text-transform: none;
                        font-weight: normal;
                    }
                }
            }
            .btn{
                text-transform: uppercase;
                border-radius: 30px;
                @media (max-width: 992px){
                    display: block;
                }
                &:hover{
                    color: #fff;
                    background-color: #1f1f23;
                }
            }
        }
        &.preview{
            padding: 36px 0 0;
            .button-content{
                margin-bottom: 24px;
                &::-webkit-scrollbar{
                    @media (max-width: 992px){
                        display: none;
                    }
                }
                @media (max-width: 992px){
                    display: flex;
                    overflow-x: scroll;
                    padding-left: 16px;
                }
                .btn{
                    font-size:17px;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-right: 16px;
                    border-width: 2px; 
                    margin-bottom: 16px;
                    border-radius: 21px;
                    @media (max-width: 992px){
                        min-width: fit-content;
                    }
                    &:hover{
                        background-color: $pink;
                        color:#fff;
                        border: 2px solid $pink;
                    } 
                }
            }
            .card-content{
                @media (max-width: 767px){
                    margin-left:-16px
                }
                img{
                    height: auto;
                    width: 100%;
                }
                .card-title{
                    padding:24px 0;
                    text-transform: uppercase;
                }
                .card-slider{
                    .slick-list.draggable{
                        padding:0!important;
                    }
                    .slide{
                        padding: 0 15px;
                        a{
                            display: block;
                            text-decoration: none;
                            color:#2c2e35;
                            .special{
                                object-position: top;
                            }
                            &:hover{
                                img{
                                    opacity: .8;
                                }
                            }
                        }
                    }
                    .slick-arrow{
                        font-size:35px;
                        color:#000;
                        z-index: 1;
                        line-height: 1;
                        font-family: $poppins;
                        height: auto;
                        width: unset;
                        top: calc(50% - 40px);
                        &:before{
                            content:"";
                        }
                        &.slick-prev{
                            left: -35px;
                        }
                        &.slick-next{
                            right: -35px;
                        }
                    }
                }
            }
            .bandeau-remise{
                display: block;
                text-decoration: none;
                margin-bottom: 94px;
                @media (max-width: 767px){
                    margin-bottom: 36px;
                }
                .remise-title{
                    font-style: italic;
                    line-height: 44px;
                    @media (max-width: 992px){
                        line-height: 1;
                    }
                }
                .remise-text{
                    margin-bottom: 0;
                }
            }
            .bloc{
                width: 100%;
                text-decoration: none;
                position: relative;
                display: block;
                @media (max-width: 992px){
                    margin-bottom: 48px;
                }
                @media (max-width: 767px){
                    margin-bottom: 24px;
                }
                .bloc-text-content{
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                img{
                    width: 100%;
                    height: auto;
                    &.bloc-bg{
                        @media (max-width: 767px){
                            display: none;
                        }
                    }
                    &.bloc-bg-mobile{
                        display: none;
                        @media (max-width: 767px){
                            display: block;
                        }
                    }
                }
                .ttr{
                    line-height: 41px;
                    z-index: 2;
                    white-space: break-spaces;
                    position: relative;
                    text-transform: uppercase;
                }
                .bloc-text{
                    line-height: 60px;
                    z-index: 2;
                    position: relative;
                    @media (max-width: 992px){
                        line-height: 1.2;
                    }
                }
                &.first{
                    .bloc-text-content{
                        justify-content: space-between;
                        @media (max-width: 767px){
                            padding: 24px 16px 8px;
                        }
                    }
                    .ttr{
                        span{
                            width:fit-content;
                            padding: 0 12px;
                            &.italic-font{
                                display: block;
                                text-transform: none;
                            }
                        }
                    }
                }
                &.second{
                    img{
                        @media (max-width: 992px){
                            object-position: center;
                        }
                    }
                }
                &.first:after , &.second:after{
                    content:"";
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.36);
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }
                &.third{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: #b4d6ca;
                    @media (max-width: 992px){
                        margin-bottom: 24px;
                    }
                    @media (max-width: 767px){
                        padding-top:16px;
                        padding-bottom: 16px;
                    }
                    .ttr{
                        line-height: 1.5;
                        white-space: break-spaces;
                        span{
                            background: #fff;
                            text-align: center;
                        }
                    }
                }
                &.fourth{
                    margin-top:48px;
                    .bloc-text-content{
                        @media (max-width: 767px){
                            padding-bottom: 16px;
                            padding-top: 16px;
                        }
                    }
                    .ttr{
                        color:#000;
                        @media (max-width: 767px){
                            font-style: italic;
                            font-weight: 700;
                            font-size: 22px;
                            color: #2c2e35;
                            text-transform: none;
                        }
                    }
                    .btn{
                        position: relative;
                        z-index: 1;
                        text-transform: uppercase;
                        font-size: 20px;
                        padding:12px 44px;
                        border-radius: 30px;
                        width: fit-content;
                        @media (max-width: 992px){
                            font-size: 16px;
                            padding: 8px 16px
                        }
                        &:hover{
                            background-color: #1f1f23;
                            color: #fff;
                        }
                    }
                }
            }
            .large-bloc{
                margin-top: 36px;
                height: auto;
                @media (max-width: 992px){
                    margin-top: 0;
                }
                .col-md-4{
                    min-height: 100%; 
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        &.bloc-bg{
                            @media (max-width: 767px){
                                display: none;
                            }
                        }
                        &.bloc-bg-mobile{
                            display: none;
                            @media (max-width: 767px){
                                display: block;
                            }
                        }
                    }
                    &.special{
                        padding:0;
                        @media (max-width: 992px){
                            padding-left: 12px;
                            padding-right: 12px;
                            height: unset;
                        }
                        @media (max-width: 767px){
                            height: 100%;
                            margin-top: -60px;
                        }
                        .bloc{
                            height: 100%;
                            padding:16px 0;
                        }
                    }
                }
                .bloc{
                    position: relative;
                    z-index: 1;
                    .path{
                        position: absolute;
                        height: 100%;
                        width: auto;
                        @media (max-width: 767px){
                            display: none;
                        }
                        &.left{
                            left: -80px;
                        }
                        &.right{
                            left: unset;
                            right: -80px;
                        }
                    }
                    .btn{
                        border: 1px solid #fff;
                        text-transform: uppercase;
                        position: relative;
                        width: fit-content;
                        margin-top: 64px;
                        white-space: break-spaces;
                        &:hover{
                            background-color: #fff;
                            color:#b4d6ca;
                        }
                        @media (max-width: 1280px){
                            font-size:20px;
                            margin-top:24px;
                        }
                        @media (max-width: 992px){
                            font-size:18px;
                        }
                        @media (max-width: 767px){
                            margin-top: 16px;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        &.about{
            padding: 100px 0;
            @media (max-width: 992px){
                padding:24px 0;
            }
            .col-md-3{
                text-align: center;
                position: relative;
                @media (max-width: 992px){
                    margin-bottom: 24px;
                }
                .about-picture{
                    height: 175px;
                    object-fit: contain;
                    width: 155px;
                    @media (max-width: 992px){
                        height: 90px;
                        width: auto;
                    }
                }
                .about-title{
                    text-transform: uppercase;
                    margin-top:16px;
                    margin-bottom: 0;
                    @media (max-width: 992px){
                        font-size: 14px;
                    }
                }
                &:not(:last-child):after{
                    content:"";
                    width: 1px;
                    height: 50%;
                    background-color: #000;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    @media (max-width: 992px){
                        display: none;
                    }
                }
            }
        }
        &.avantages{
            background-color: #7d9db7;
            padding: 24px 0 48px;
            .ttr{
                font-size: 46px;
                line-height: 85px;
                margin-bottom: 42px;
                position: relative;
                @media (max-width: 992px){
                    font-size:18px
                }
                .title-content{
                    background-color: #7d9db7;
                    position: relative;
                    padding: 0 24px;
                }
                &:before{
                    content: "";
                    width: 100%;
                    border-top:2px dashed #fff;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    @media (max-width: 992px){
                        display: none;
                    }
                }
            }
            .sttr{
                font-size: 26px;
                line-height: 43px;
                font-weight: 700;
                @media (max-width: 992px){
                    font-size: 18px;
                }
            }
            .para{
                font-size: 24px;
                @media (max-width: 992px){
                    font-size: 16px;
                }
            }
            .payment-secured{
                max-width: 170px;
                @media (max-width: 992px){
                    max-width: 100px;
                }
            }
        }
    }
    .engagement{
        padding:0 3.75%;
        @media (min-width: 1921px){
            max-width: 1920px;
            margin: 0 auto;
            padding:0 60px;
        }
        @media (max-width: 991px){
            padding: 0;
            overflow-x: hidden;
        }
        .section{
            .ttr{
                span{
                    display: block;
                }
            }
            &.preview{
                padding:64px 30px 0;
                .large-bloc{
                    margin-top: 0;
                    .bloc.third{
                        .ttr{
                            line-height: 1.5;
                            font-style: italic;
                        }
                    }
                }
            }
            &.captain-engagement{
                @media (max-width: 991px){
                    padding: 0;
                }
                .text-content{
                    display: flex;
                    align-items: center;
                    @media (max-width: 991px){
                        flex-direction: column;
                    }
                    .text-content-title{
                        width: 45%;
                        @media (max-width: 991px){
                            width: 100%;
                        }
                    }
                    .text-content-para{
                        width: 55%;
                        @media (max-width: 991px){
                            width: 100%;
                            padding: 24px 16px;
                        }
                    }
                    article{
                        position: relative;
                        img.d-lg-none{
                            width: 100%;
                        }
                        .ttr{
                            font-size:4.75vw;
                            line-height: 1.32;
                            font-weight: 900;
                            font-style: italic;
                            @media (max-width: 991px){
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 100%;
                                line-height: 1.2;
                                filter: drop-shadow(2.121px 2.121px 2.834645669291339px rgba(87,89,94,0.48));
                            }
                            span{
                                color: unset;
                                font-style: normal;
                            }
                        }
                        .para{
                            font-size: 1.5vw;
                            text-align: justify;
                            white-space: break-spaces;
                            @media (min-width: 1921px){
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            &.responsable{
                background-color: rgba(180, 214, 202, .278);
                padding: 32px;
                margin-bottom: 96px;
                @media (max-width: 991px){
                    margin-bottom: 36px;
                }
                .ttr{
                    font-style: italic;
                    margin-bottom: 64px;
                    @media (max-width: 991px){
                        font-size: 32px;
                    }
                }
                .card{
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    text-align: center;
                    @media (max-width: 991px){
                        margin-bottom: 32px;
                    }
                    .card-title-content{
                        position: relative;
                        width: fit-content;
                        margin: 0 auto;
                        img{
                            width: 80px;
                            height: 80px;
                            object-fit: none;
                            position: absolute;
                            bottom: 0px;
                            right: -50px;
                            @media (max-width: 991px){
                                width: 40px;
                                height: 40px;
                                bottom: 0;
                                right: -40px;
                                object-fit: contain;
                            }
                        }
                        .card-title{
                            font-size: 5.625vw;
                            line-height: 85px;
                            color: #91aba3;
                            font-weight: 800;
                            font-style: italic;
                            width: fit-content;
                            opacity: 0.271;
                            @media (max-width: 991px){
                                line-height: 1;
                            }
                        }
                    }
                    .card-text{
                        font-size: 1.25vw;
                        color: #7f988f;
                        font-style: italic;
                        white-space: break-spaces;
                    }
                }
            }
            &.engagement-list{
                padding: 0 30px;
                @media (max-width: 991px){
                    padding: 0;
                }
                .row{
                    margin-bottom: 96px;
                    @media (max-width: 991px){
                        margin-bottom: 36px;
                    }
                    &.reverse{
                        @media (max-width: 991px){
                            flex-direction: column-reverse;
                        }
                    }
                    .bloc-text{
                        text-align: center;
                        padding: 0 30px;
                        @media (max-width: 991px){
                            margin-bottom: 36px;
                            padding: 0 16px;
                        }
                        .numero{
                            font-size: 32px;
                            color: #000000;
                            font-weight: bold;
                            font-style: italic;
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            @media (max-width: 991px){
                                font-size: 24px;
                            }
                            .num{
                                font-size: 5.625vw;
                                line-height: 1.05;
                                font-weight: 800;
                                @media (max-width: 991px){
                                    font-size: 40px;
                                    line-height: 56px;
                                }
                            }
                        }
                        .ttr{
                            font-size: 2vw;
                            line-height: 1.8;
                            font-weight: 900;
                            font-style: italic;
                            @media (max-width: 991px){
                                margin-bottom: 32px;
                                line-height: 1.2;
                            }
                        }
                        .para{
                            font-size: 1.5vw;
                            line-height: 1.375;
                            text-align: justify;
                            white-space: break-spaces;
                            @media (max-width: 991px){
                                font-size: 16px;
                            }
                            @media (min-width: 1921px){
                                font-size:24px;
                            }
                        }
                    }
                }
            }
            &.plus-loin{
                margin-bottom:42px;
                .ttr{
                    line-height: 1.43;
                    font-weight: 800;
                    white-space: break-spaces;
                    @media (max-width: 991px){
                        line-height: 1.2;
                        margin-bottom: 36px;
                    }
                }.horizontal-cards{
                    .pic{
                        min-width: 80px;
                        @media (max-width: 991px){
                            min-width: 60px;
                        }
                        img{
                            width: 70px;
                            height: 70px;
                            object-fit: none;
                            @media (max-width: 991px){
                                width: 40px;
                                height: 40px;
                                object-fit: contain;
                            }
                        }
                    }
                    .para{
                        font-size: 1.5vw;
                        line-height: 1.375;
                        margin-bottom: 0;
                        @media (max-width: 991px){
                            font-size: 16px;
                            line-height: 1;
                        }
                        @media (min-width: 1921px){
                            font-size:24px;
                        }
                    }
                }
            }
            &.faq{
                position: relative;
                img{
                    width: 100%;
                }
                .text-content{
                    position: absolute;
                    left: 0;
                    top:0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .ttr{
                        line-height: 85px;
                        font-style: italic;
                    }
                }
            }
            &.faq-content{
                position: relative;
                margin-bottom: 64px;
                .faq-list{
                    list-style: none;
                    width: auto;
                    margin:-84px 56px 0;
                    padding-left: 0;
                    background-color: #fff;
                    @media (max-width: 991px){
                        margin: 0;
                    }
                    li{
                        margin: 0 48px;
                        padding: 16px 0;
                        border-bottom: 1px solid #57595e;
                        @media (max-width: 991px){
                            margin: 0 24px;
                        }
                        .faq-title{
                            cursor: pointer;
                            font-size: 1.81vw;
                            line-height: 1.45;
                            color: #000000;
                            font-style: italic;
                            width: 100%;
                            align-items: center;
                            display: flex;
                            justify-content: space-between;
                            font-weight: 500;
                            text-decoration: none;
                            @media (max-width: 991px){
                                font-size: 18px;
                                line-height: 1.2;
                            }
                            @media (min-width: 1921px){
                                font-size:29px;
                            }
                            &.collapsed{
                                i{
                                    transform: none;
                                    transform-origin: center;
                                }
                            }
                            i{
                                font-style: normal;
                                font-family: $poppins;
                                font-size: 36px;
                                transition: all .1s ease;
                                transform: rotate(90deg);
                                @media (max-width: 991px){
                                    font-size: 18px;
                                    max-height: 10.63px;
                                }
                            }
                        }
                        div.collapse{
                            height: 0;
                            transition: height .35s ease;
                            &.show{
                                height: auto;
                            }
                        }
                        p{
                            font-size: 1.31vw;
                            color: #57595e;
                            padding: 16px 0 16px 32px;
                            opacity: .7;
                            @media (max-width: 991px){
                                padding-left: 0;
                                line-height: 1.2;
                            }
                            @media (min-width: 1921px){
                                font-size:21px;
                            }
                        }
                    }
                }
            }
            &.question{
                text-align: center;
                padding:48px 0;
                @media (max-width: 991px){
                    padding: 24px 16px;
                }
                .ttr{
                    line-height: 1.1;
                    margin-bottom: 48px;
                    @media (max-width: 991px){
                        font-size: 24px;
                        line-height: 1.2;
                        margin-bottom: 32px;
                    }
                }
                .para{
                    font-size: 1.5vw;
                    margin-bottom: 48px;
                    @media (max-width: 991px){
                        font-size: 16px;
                        line-height: 1.2;
                        margin-bottom: 32px;
                    }
                    @media (min-width: 1921px){
                        font-size: 24px;
                    }
                }
                .btn{
                    border-radius: 30px;
                    font-style: italic;
                }
            }
            &.video-engagement{
                .ttr{
                    font-weight: 900;
                    font-size: 3.75vw;
                    text-align: center;
                    margin-bottom: 2rem;
                    span{
                        display: inline-block;
                        font-weight: 300;
                        margin-left: 1vw;
                    }
                    @media(max-width:991px){
                        font-size: 24px;
                    }
                }
                .video-container{
                    overflow: hidden;
                    .video-wrapper{
                        position: relative;
                        padding-bottom: 56.25%;
                        height: 0;
                        overflow: hidden;
                        width:100%;
                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .bloc-wrapper{
        padding: 1.56%;
        @media (max-width:767px){
            padding:8px
        }
        .bloc{
            margin-bottom: 1.56%;
            @media (max-width:767px){
                margin-bottom: 8px;
            }
            .bloc-link{
                display: block;
                text-decoration: none;
                position: relative;
                img{
                    width: 100%;
                    transition: all .1s ease-in-out;
                }
                .bloc-title{
                    position: absolute;
                    width: 100%;
                    bottom: 28.75%;
                    left: 0;
                }
                .bloc-subtitle{
                    position: absolute;
                    left: 2.34%;
                    bottom: 1.56%;
                    font-weight: 700;
                    transition: all .1s ease-in-out;
                    a{
                        color: #fff;
                        font-size: 1.5vw;
                        text-decoration: none;
                        display: block;
                        @media (min-width: 1921px){
                            font-size: 28px;
                        }
                        @media (max-width: 767px){
                            font-size:18px
                        }
                        &:hover{
                            color:#ec6a8e
                        }
                    }
                }
                &:hover{
                    img{
                        opacity: .8;
                    }
                }
            }
            &:not(.first){
                @media (max-width: 767px){
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
            &.second{
                .first-content{
                    margin-right: 0.78%;
                    width: 54.7%;
                    @media (max-width: 767px){
                        width:100%;
                        margin-right: 0;
                        margin-bottom: 8px;
                    }
                }
                .second-content{
                    margin-left: 0.78%;
                    width: 45.3%;
                    @media (max-width: 767px){
                        width:100%;
                        margin-left: 0;
                    }
                }
            }
            &.third{
                .first-content{
                    width: 61.45%;
                    @media (max-width: 767px){
                        width: 100%;
                    }
                }
                .second-content{
                    width: 38.55%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 0.78%;
                    @media (max-width: 767px){
                        width: 100%;
                    }
                    .ttr{
                        position: relative;
                        margin-left: -60%;
                        @media (min-width: 1921px){
                            font-size: 120px;
                            margin-bottom: 100px;
                        }
                        @media (max-width: 767px){
                            margin-left: 0;
                            margin-top: 5vw;
                            text-align: center;
                        }
                    }
                    .para{
                        font-family: 'Baskerville', sans-serif;;
                        font-size: 1.7vw;
                        line-height: 1;
                        text-align: justify;
                        max-width: 28vw;
                        @media (max-width: 767px){
                            max-width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            &.fifth{
                .first-content{
                    width: 27.35%;
                    margin-right: 0.78%;
                    @media (max-width: 767px){
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 16px;
                    }
                }
                .second-content{
                    width: 72.65%;
                    margin-left: 0.78%;
                    @media (max-width: 767px){
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 16px;
                    }
                }
            }
            &.seventh{
                .ttr{
                    margin-bottom: 5vw;
                    @media (min-width: 1921px){
                        font-size: 120px;
                        margin-bottom: 100px;
                    }
                }
                .first-content{
                    width: 38.55%;
                    margin-right: 0.78%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 0.78%;
                    .para{
                        line-height: 1;
                        text-align: right;
                        max-width: 22vw;
                        @media (min-width: 1921px){
                            font-size: 33px;
                            max-width: 414px;
                        }
                    }
                }
                .second-content{
                    width: 61.45%;
                    margin-left: 0.78%;
                    .ttr{
                        @media (max-width: 767px){
                            margin-top: 5vw;
                        }
                    }
                }
            }
            &.fourth,&.sixth,&.eighth{
                .first-content{
                    margin-right: 0.78%;
                }
                .second-content{
                    margin-left: 0.78%;
                    margin-right: 0.78%;
                }
                .third-content{
                    margin-left: 0.78%;
                } 
                .first-content,.second-content,.third-content{
                    width: 33.33%;
                }
            }
        }
    }
    .news-slider{
        width: 100%;
        .news-text{
            margin: 0;
            a{
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .slick-arrow{
            font-size:35px;
            z-index: 1;
            font-family: $poppins;
            line-height: 1;
            height: auto;
            width: unset;
            @media (max-width: 992px){
                font-size: 20px;
            }
            &:before{
                content:"";
            }
            &.slick-prev{
                left: 25px;
            }
            &.slick-next{
                right: 25px;
            }
        }
    }
    .content{
        @media (min-width: 1921px){
            padding: 0!important;
            max-width: 1920px;
            margin: 0 auto;
        }
        &.special{
            @media (max-width: 992px){
                padding: 0;
            }
        }
    }
    .rose{
        background-color: $pink;
        color:#fff;
    }
    .italic-font{
        font-family: $amalfi;
    }
    .home .btn:focus{
        box-shadow: none;
    }
}