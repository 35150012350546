@charset "UTF-8";
@import url("https://p.typekit.net/p.css?s=1&k=lyn6edt&ht=tk&f=5898.5899.5901.5902.5903.13429.13430.13432.13433.13434&a=126570087&app=typekit&e=css");
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/706098/000000000000000077359a7d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/706098/000000000000000077359a7d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/706098/000000000000000077359a7d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/202077/000000000000000077359a81/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/202077/000000000000000077359a81/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/202077/000000000000000077359a81/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/40148d/000000000000000077359a87/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/40148d/000000000000000077359a87/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/40148d/000000000000000077359a87/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/ac8fff/000000000000000077359a8a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/ac8fff/000000000000000077359a8a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/ac8fff/000000000000000077359a8a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/40fade/000000000000000077359a8d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/40fade/000000000000000077359a8d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/40fade/000000000000000077359a8d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/d666a1/00000000000000000001735c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/d666a1/00000000000000000001735c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/d666a1/00000000000000000001735c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/d574eb/000000000000000077359a90/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/d574eb/000000000000000077359a90/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/d574eb/000000000000000077359a90/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/24f208/000000000000000077359a95/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/24f208/000000000000000077359a95/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/24f208/000000000000000077359a95/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/69acfd/000000000000000077359a97/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff2"),url("https://use.typekit.net/af/69acfd/000000000000000077359a97/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("woff"),url("https://use.typekit.net/af/69acfd/000000000000000077359a97/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:800;font-stretch:normal;
}
@font-face {
    font-family:"LFT Etica";
    src:url("https://use.typekit.net/af/dbe8a0/000000000000000077359a98/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/dbe8a0/000000000000000077359a98/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/dbe8a0/000000000000000077359a98/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}
.front.engagement{
    font-family: LFT Etica, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #0F0F10;
    font-weight: 300;
    strong, b {
        font-weight: 700;
    }
    a {
        text-decoration: none;
        color: #CE6BA4;
        :hover {
            color: #CE6BA4;
            text-decoration: underline;
        }
    }
    ::selection {
      background:#CE6BA4;
        color: #FFFFFF;
        
    }
    ul {
        margin-bottom: 0;
        padding-left: 1em;
        li {
            position: relative;
            margin-bottom: .3em;
            padding-left: 30px;
            list-style: none;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 27px;
                height: 17px;
                background: url("../img/recrutement/icon-arrow-right.svg") no-repeat;
                background-size:cover;
                left: -10px;
                top: 5px   
            }
        }
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 800;
        font-style: italic;
        span{
            font-size:inherit;
            font-family:inherit;
            color:inherit;
        }
    }
    h1 {
        font-size: 2.7em;
        margin-top: 1em;
        color: #ffffff;
        span {
            font-weight: 300;
            font-style: italic;
        }
    }
    .txtbg-rose {
        display: inline;
        color: #ffffff;
        padding-left: 10px;
        padding-right: 10px;
        background:  no-repeat 0 100%;
        background-image: linear-gradient(#CE6BA4, #CE6BA4);
        background-size: 100% 70%;
    }
    .txt-rose {
        display: inline;
        color:#CE6BA4
    }
    h2 {
        font-size: 2.2em;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .txtweight-300 {
        font-weight: 300
    }
    h3 {
        font-size: 1.33em;
        margin-bottom: 1em;
    }
    h4 {
        font-size: 1.25em;
        margin-bottom: 1em
    }
    img {
        max-width: 100%;
    }
    .bg-rose {
        background-color: #FFF2F9
    }
    .carousel{
        background:transparent;
    }
    button, .btn, [type=button], [type=reset], [type=submit] {
        display: inline-block;
        border-radius: 0px;
        border: 0;
        line-height: 1.5;
        margin-top:1em;
        outline: none;
        font-style: italic;
        text-decoration: none;
        position: relative;
        transition: all .3s;
        &:hover,&:focus{
            background-color: #BF458B;
            color: #ffffff;
            text-decoration: none;
            outline:0;
        }
    }
    :-moz-placeholder,
    ::-moz-placeholder{ 
        color: #014003;
        opacity: .7
    } 
    :-ms-input-placeholder{
        color: #014003;
        opacity: .7
    }
    ::-webkit-input-placeholder,
    ::placeholder{
        color: #014003;
        opacity: .7
    }
    section {
        width: 100%;
        position: relative;
        padding-top: 3em;
        padding-bottom: 4em;
        max-width:100%;
        margin-bottom:0;
    }
    header {
        position: absolute;
        z-index: 4;
        color: #ffffff;
        width: 100%;
        background-color:transparent!important;
        .col-auto {
            padding: 0
        }
    }
    .bloc-hero {
        padding-top: 15em;
        position: relative;
         z-index: 3;
        background-color: #ffffff;
        padding-bottom: 0;
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            max-height: 550px;
            top: 0;
            left: 0;
            background-size: cover;
        }
        .col-md-7 {
            padding-bottom: 1em
        }
        .col-md-6:first-of-type {
            margin-bottom: 2em
        }
        .container {
            position: relative;
            z-index: 3;
        }
    }
    .formulaire {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
        min-height: 500px;
        & > div > p {
            margin-bottom: 2em;
        }
        h2 {
            margin-top: 0;
            margin-bottom: .3em;
            .txtbg-rose {
                display: inline-block
            }
        }
    }
    .bloc-video {
        padding-top: 1em;
        background-color: #ffffff;
        position: relative;
        z-index: 1
    }
    .video-container { 
        overflow:hidden; 
        padding-bottom:56.25%; 
        position:relative; 
        height:0;
        margin-bottom: 2em;
        iframe {
            left:0; 
            top:0; 
            height:100%;
            width:100%;
            position:absolute;
        }
    }
    .bloc-illus{
        h2 {
            margin-bottom: 0;
        }
        h3 {
            margin-bottom: 3em
        }
        h4 {
            color:#CE6BA4
        }
        .col-md-6 {
            padding-bottom: 2em;
        }
        p {
            line-height: 1.7
        }
    } 
    .bloc-videoslider {
        padding-bottom: 1em;
        b {
            font-weight: 600
        }
        p,li {
            line-height: 1.7
        }
    }
    #carouselVideoslider {
        padding-bottom: 50px;
        background-color: transparent;
        .carousel-item{
            margin-right:-100%;
        }
        .carousel-indicators {
            bottom: 15px;
            [data-bs-target] {
                box-sizing: content-box;
                flex: 0 1 auto;
                width: 33%;
                height: inherit;
                padding: 0;
                margin-right: 3px;
                margin-left: 3px;
                text-indent:0;
                color: #000;
                font-size: 16px;
                line-height: 1;
                font-style: normal;
                font-weight: 300;
                margin-top:0;
                cursor: pointer;
                background-color: #fff!important;
                background-clip: padding-box;
                text-transform: none;
                border: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                opacity: .5;
                transition: opacity .6s ease;
            }
            .active {
                opacity: 1;
                font-style: italic;
                font-weight: 600;
                color: #CE6BA4;
                background-color:transparent;
            }
        }
    }
    .bloc-carrousel {
        padding-bottom: 3em;
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 70%;
            bottom: 0;
            left: 0;
        }
        .container-fluid {
            position: relative
        }
    }
    #Carousel-savoirplus {
        overflow: hidden;
        padding-top: 5em;
        .carousel-item{
            margin-right:-100%;
        }
    }
    .bloc-2column{
        padding-top: 6em;
        padding-bottom: 2em;
        h2 {
            margin-top: 0
        }
        p,ul li {
            line-height: 1.7;
            margin-bottom: 1.5em
        }
        ul li:last-of-type {
            margin-bottom: 0em
        }
        .container > h2 {
            margin-top: 0;
        }
        .row .txt{
            position: relative;
            z-index: 1;
        }
        .wrap {
            position: relative;
            padding: 3em 0;
            min-height: 606px;
            &:nth-of-type(4) {
                padding-bottom: 0em
            }
        }
    }
    .bloc-checkbox .bandeau-rose {
        background-color: #CE6BA4;
        padding-top: .5em;
        padding-bottom: .5em;
        font-size: .75em;
        color: #ffffff;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        margin-bottom: 3em;
    }
    .bloc-decouvert {
        padding-bottom: 2em;
        h2 {
            margin-top: 1em;
            margin-bottom: 2em;
        }
        .d-item {
            position: relative;
            padding-bottom: 100%;
            display: block;
            figure {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: rgb(15,15,16);
                background: -moz-linear-gradient(0deg, rgba(15,15,16,1) 0%, rgba(15,15,16,0) 100%);
                background: -webkit-linear-gradient(0deg, rgba(15,15,16,1) 0%, rgba(15,15,16,0) 100%);
                background: linear-gradient(0deg, rgba(15,15,16,1) 0%, rgba(15,15,16,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f0f10",endColorstr="#0f0f10",GradientType=1);
                }
            }
            .d-txt {
                position: absolute;
                width: 100%;
                color: #ffffff;
                font-weight: 700;
                font-style: italic;
                padding:.5em 1.5em;
                font-size: 1.2em;
                bottom: 0;
                left: 0
            }
        }
        .col-lg-3 {
            padding-bottom: 2em;
            &:nth-of-type(2n) {
                padding-top: 2em;
            }
        }
    }
    .bloc-presse .bb-1 {
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 75%;
            height: 1px;
            background-color: #CE6BA4;
            bottom: 12px;
            left: 12.5%;
        }
        .row{
            .col-8{
                p{
                    white-space: break-spaces;
                }
            }
        }
    }
    footer {
        border-top: 3px solid #ffffff;
    }
    .btn-bottom {
        position: fixed;
        width: 100%;
        height: 46px;
        z-index: 1;
        top: calc(100% - 46px);
        .btn {
            width: 100%;
            height: 100%;
            margin-top: 0;
        }
    }
    .mobile-hidden {
        display: none
    }
    .desktop-hidden {
        display: block;
    }
    #Carousel-rs {
        min-height: 200px;
        padding-left: 5%;
        padding-right: 5%;
        ul li {
            list-style: inherit;
            padding-left: 0;
            &:before {
                display: none;
            }
        }
        p {
            margin: 0
        }
        .col-md-4 {
            font-size: 14px;
            padding: 1em 1.5em;
            text-align: center
            img {
                border-radius: 50%;
                max-width: 100px;
            }
            .col-sm-8 {
                padding-top: 5%
            }
        }
    } 
    @media (max-width: 767px) {
        #Carousel-savoirplus .carousel-inner .carousel-item > div, #Carousel-rs .carousel-inner .carousel-item > div {
            display: none;
        }
        #Carousel-savoirplus .carousel-inner .carousel-item > div:first-child,
        #Carousel-rs .carousel-inner .carousel-item > div:first-child {
            display: block;
        }
    }
    #Carousel-savoirplus .carousel-control-next, #Carousel-savoirplus .carousel-control-prev {
        top: 20px;
        display: block;
        bottom: inherit
    }
    #Carousel-savoirplus .carousel-control-prev {
        left: 35%;
    }
    #Carousel-savoirplus .carousel-control-next {
        right: 35%;
    }
    .carousel-control-prev-icon {
        background-image: url("https://rejoignez.fr.captaintortue.com/hubfs/icon-arrow-left.svg");
    }
    .carousel-control-next-icon {
        background-image: url("https://rejoignez.fr.captaintortue.com/hubfs/icon-arrow-right.svg");
    }
    #Carousel-savoirplus .carousel-inner .carousel-item.active,
    #Carousel-savoirplus .carousel-inner .carousel-item-next,
    #Carousel-savoirplus .carousel-inner .carousel-item-prev, #Carousel-rs .carousel-inner .carousel-item.active,
    #Carousel-rs .carousel-inner .carousel-item-next,
    #Carousel-rs .carousel-inner .carousel-item-prev {
        display: flex;
    }
    #Carousel-savoirplus .carousel-inner .carousel-item-end.active,
    #Carousel-savoirplus .carousel-inner .carousel-item-next, #Carousel-rs .carousel-inner .carousel-item-end.active,
    #Carousel-rs .carousel-inner .carousel-item-next {
          transform: translateX(100%);
    }
    #Carousel-savoirplus .carousel-inner .carousel-item-start.active, 
    #Carousel-savoirplus .carousel-inner .carousel-item-prev, #Carousel-rs .carousel-inner .carousel-item-start.active, 
    #Carousel-rs .carousel-inner .carousel-item-prev {
      transform: translateX(-100%);
    }
    #Carousel-savoirplus .carousel-control-next, #Carousel-savoirplus .carousel-control-prev , #Carousel-rs .carousel-control-next, #Carousel-rs .carousel-control-prev {
        width: 10%
    }
    @media (min-width: 768px) {
        &{
            font-size: 16px;
        }
        .mobile-hidden {
            display: block
        }
        a.mobile-hidden {
            display: inline-block
        }
        .desktop-hidden {
            display: none;
        }
        h1 {
            font-size: 3em;
        }
        section {
            padding-bottom: 5em;
        }
        .formulaire {
            margin-bottom: -9em;
        }
        .bloc-hero{
            padding-bottom: 4em;
        }
        .bloc-hero .col-md-7 {
            padding-bottom: 0em
        }
        .bloc-video .col-md-5 {
            padding-left: 10%;
    
        }
        .bloc-videoslider .col-md-5 {
            padding-top: 6em;
            padding-left: 5%;
        }
        .bloc-hero {
            padding-top: 8em
        }
        .bloc-hero:before {
            height: 95%;
            max-height: inherit;
            background-size: cover;
        }
        .bloc-2column {
            padding-bottom: 5em;
        }
        .bloc-2column .wrap:nth-of-type(2n) .visu {
            left: inherit;
            right: 0
        }
        .bloc-2column .row:nth-of-type(2n+1) .visu img {
            left: 0
        }
        .bloc-2column .row:nth-of-type(2n) .visu img {
            right: 0;
        }
        .bloc-2column .wrap .col-md-6 img {
            transform: scale(1.1);
        }
        .bloc-2column .wrap:nth-of-type(2n) .col-md-6 img {
            transform-origin: right
        }
        .bloc-2column .wrap:nth-of-type(2n+1) .col-md-6 img {
            transform-origin: left
        }
        .bloc-2column .row .txt {
            margin-left: inherit;
        }
        .bloc-presse .bb-1:after {
            display: none
        }
        .bloc-presse .w-md-75 {
            width: 75%
        }
        #Carousel-savoirplus{
            .carousel-control-prev {
                left: 80%;
            }
            .carousel-control-next {
                right: 10%;
            }
            .carousel-inner {
                transform: scale(1.5);
                .carousel-item .col-md-4 {
                    padding: 3em;
                }
            }
            .carousel-control-next,.carousel-control-prev{
                width: 15%;
                max-width: 66px
            }
        }
        #Carousel-rs .carousel-control-next, #Carousel-rs .carousel-control-prev {
            width: 15%;
            max-width: 66px
        }
        #Carousel-savoirplus .carousel-inner .carousel-item-end.active,
        #Carousel-savoirplus .carousel-inner .carousel-item-next, #Carousel-rs .carousel-inner .carousel-item-end.active, #Carousel-rs .carousel-inner .carousel-item-next {
          transform: translateX(33.333%);
        }
        
        #Carousel-savoirplus .carousel-inner .carousel-item-start.active, 
        #Carousel-savoirplus .carousel-inner .carousel-item-prev, #Carousel-rs .carousel-inner .carousel-item-start.active, 
        #Carousel-rs .carousel-inner .carousel-item-prev {
          transform: translateX(-33.333%);
        }
        
        #Carousel-savoirplus .carousel-inner .carousel-item.active .col-md-4:nth-of-type(2) .pc-visu {
            transform: scale(1.3);
            transition: all .3s;
                
        }
        #Carousel-savoirplus .carousel-inner .carousel-item-prev .col-md-4:nth-of-type(2) .pc-visu,
        #Carousel-savoirplus .carousel-inner .carousel-item-next .col-md-4:nth-of-type(2) .pc-visu,
        #Carousel-savoirplus .carousel-inner .carousel-item-end.active .col-md-4:nth-of-type(2) .pc-visu,
        #Carousel-savoirplus .carousel-inner .carousel-item-start.active .col-md-4:nth-of-type(2) .pc-visu,
        #Carousel-savoirplus .carousel-inner .carousel-item.active .col-md-4 .pc-visu {
            transform: scale(1);
            transition: all .3s;
        }
        #Carousel-savoirplus .carousel-inner .carousel-item-prev .col-md-4:nth-of-type(1) .pc-visu,
        #Carousel-savoirplus .carousel-inner .carousel-item-prev .col-md-4:nth-of-type(3) .pc-visu {
           display: none
        }
        #Carousel-savoirplus .carousel-inner .carousel-item-next .col-md-4:nth-of-type(1)  .pc-visu {
            display: none
        }
        #Carousel-rs .col-md-4 {
            text-align: left
        }
        label{
            font-size: 16px;
        }
        .hs_phone, .hs_zip {
            float: left;
            width: 49%
        }
        .hs_zip {
            margin-left: 2%
        }
    }
    #Carousel-savoirplus .carousel-inner .carousel-item-end,
    #Carousel-savoirplus .carousel-inner .carousel-item-start, #Carousel-rs .carousel-inner .carousel-item-end,
    #Carousel-rs .carousel-inner .carousel-item-start { 
      transform: translateX(0);
    }
    @media (max-width: 376px) {
        h1 {
            font-size: 1.9em;
        }
    }
    @media (min-width: 992px) {
        #Carousel-savoirplus {
            min-height: 370px;
            max-height: 370px;
        }
        .hs-firstname, .hs-lastname {
            width: 49%;
            float: left
        }
        .hs-lastname {
            margin-left: 2%
        }
        .hs-email {
            clear: both
        }
    }
    @media (min-width: 1200px) {
        #Carousel-savoirplus .carousel-inner {
            transform: scale(1.3)
        }
        #Carousel-savoirplus {
            min-height: 450px;
            max-height: 450px;
        }
        .bloc-2column {
            padding-bottom: 10em;
        }
    }
    .hbspt-form ul {
        padding-left: 0;
        li {
            padding-left:0;
            &:before {
                display: none
            }
        }
    }
    .hs-button{
        margin:0;
        text-align:center;
        border-style:solid;
        border-width:1px;
        
        display: inline-block;
        border-radius: 0px;
        background-color: #CE6BA4;
        color: #ffffff;
        border: 0;
        cursor: pointer;
        font-size: 1.625em;
        line-height: 1.5;
        padding: .25em 1.5em;
        margin-top:.2em;
        outline: none;
        font-family: LFT Etica, sans-serif;
        font-weight: 700;
        font-style: italic;
        text-decoration: none;
        position: relative;
        transition: all .3s;
        box-shadow: none;
        &:hover,&:focus{
            background-color: #BF458B;
            color: #ffffff;
        }
        &:active{
            background-color:#e66e50;
            border-color:#e66e50
        }
    }
    label{
        font-size:14px;
        color:#2E2E31;
        display:block;
        float:none;
        width:auto;
        font-weight:300;
        line-height:20px;
        padding-top:0;
        margin-bottom:4px;
        
        font-family:LFT Etica, sans-serif;
    }
    .hs-fieldtype-text {
        position: relative
    }
    .hs-video-form .hs-back-button{
        background-color:#fff;
        width:20%;
        height:38px;
        margin-right:10px;
        border:1px solid;
        -webkit-border-radius:3px;
        -moz-border-radius:3px;
        -ms-border-radius:3px;
        border-radius:3px
    }
    .hs-video-form{ 
        label{
            color:#fff !important;
        }
        .hs-button{
            width:70%
        }
        .hs-button span{
            font-size:15px
        }
        .hs-richtext{
            color:#fff !important
        }
        .legal-consent-container{
            max-height:180px !important;
            overflow-y:scroll !important;
            &:after{
                content:"";
                display:block;
                height:100px;
                width:100%
            }
        }
        .legal-consent-wrapper{
            position:relative
        }
        .legal-consent-overlay{
            position:absolute;
            pointer-events:none;
            left:0;
            bottom:0;
            height:100px;
            width:100%;
            background:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #292929 100%)
        }
    }
    .hs-error-msgs.inputs-list label{
        font-size: 13px;
        margin: 5px 0;
        font-style:italic;
        color:#f2545b
    }
    select.is-placeholder{
        color:#2E2E31;
        &:focus{
            color:#2E2E31
        }
    }
    .hs-input{
        display:inline-block;
        width:100%;
        /*max-width:500px;*/
        min-height:40px;
        font-family:LFT Etica, sans-serif;
        font-weight:normal;
        box-sizing:border-box;
        border-radius: 0px;
        border: 1px solid #FFF2F9;
        box-shadow: none;
        font-size: 14px;
        color: #2E2E31;
        padding: 9px 15px;
        &[type=checkbox],&[type=radio]{
            cursor:pointer;
            width:auto;
            height:auto;
            padding:0;
            margin:3px 5px 3px 0px;
            line-height:normal;
            border:none;
             opacity: 0;
            position: absolute;
            & + span{
                position: relative;
                display: inline-block;
                padding-left: 35px !important;
                &:before{
                    content: "";
                    background: #ffffff;
                    border:1px solid #CE6BA4;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                    text-align: center;
                    font-size: 15px;
                    line-height: 18px;
                    position: absolute;
                    top: -3px;
                    left: 0;
                    box-shadow: none;
                }
            }
        }
        &[type=checkbox]{
            & + span:before {
                width: 21px;
                height: 21px;
            }
            &:checked + span:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 26px;
                height: 17px;
                background: url("https://rejoignez.fr.captaintortue.com/hubfs/icon-check.svg") no-repeat;
                background-size: cover;
                margin-right: 10px;
                position: absolute;
                top: -4px;
                left: 3px;
                border-radius: 0;
                box-shadow: none;
            }
        }
        &[type=radio]{
            & + span:before {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            &:checked + span:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: #CE6BA4;
                left: 3px;
                top: 0;
            }
        }
        &[type=file]{
            padding:initial;
            border:initial;
            line-height:initial;
            box-shadow:none
        }
        &:-moz-placeholder,&:-webkit-input-placeholder{
            color:#2E2E31
        }
        option:disabled{
            color:#2E2E31
        }
        input, textarea{
            transition:border .2s linear
        }
        &:focus{
            outline:none;
            border-color:rgba(82,168,236,.8)
        }
    }
    textarea.hs-input{
        height:auto
    }
    select[multiple].hs-input{
        height:inherit
    }
    input.hs-input.error,div.field.error input,div.field.error textarea,div.field.error .chzn-choices,textarea.hs-input.error,select.hs-input.error{
        border-color:#c87872
    }
    input.hs-input.error:focus,div.field.error input:focus,div.field.error textarea:focus,div.field.error .chzn-choices:focus,textarea.hs-input.error:focus,select.hs-input.error:focus{
        border-color:#b9554d
    }
    .actions{
        margin-top:18px;
        margin-bottom:18px;
        /*padding:17px 0px*/
    }
    .inputs-list{
        margin:0 0 5px;
        width:100%;
        padding-left:5px;
        & >li{
            display:block;
            padding:0;
            width:100%;
            padding-top:0;
            &:before{
                display: none;
            }
        }
        label{
            float:none;
            width:auto;
            padding:0;
            line-height:18px;
            white-space:normal;
            font-weight:normal;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        &:first-child{
            padding-top:6px
        }
        &>li+li{
            padding-top:2px
        }
    }
    ul.no-list{
        list-style:none
    }
    .field{
        margin-bottom:10px
    }
    .hs-field-desc{
        color:#CE6BA4;
        margin:0px 0px 6px;
        font-size:14px;
        font-style: italic;
        font-family:LFT Etica, sans-serif;
    }
    .hs-form-required{
        color:red
    }
    .hs-richtext{
        margin-bottom:3px;
        font-family:LFT Etica, sans-serif;
        line-height:1.4;
        font-size:12px;
        font-style: italic;
        hr{
            margin-left:0;
            width:91%
        }
    }
    .hs-custom-style .hs-dependent-field>div input.hs-input:not([type=checkbox]):not([type=radio]){
        width:90%
    }
    form.hs-form-rtl{ 
        .field{
            flex:0 1 100%
        }
        .hs-input[type=checkbox],.hs-input[type=radio]{
            margin:3px 5px 3px 5px;
        }
        fieldset{
            display:flex;
            &[class^=form-columns-] .input{
                margin-right:0px
            }
        }
        ul{
            padding:0px
        }
        .legal-consent-container .hs-form-booleancheckbox-display{ 
            input{
                width:auto;
                float:right
            }
            &>span{
                margin-left:0px
            }
        }
        .hs-dependent-field{
            display:flex;
            flex:0 1 100%;
            flex-wrap:wrap
        }
    }
    .email-correction,.email-validation{
        padding-top:3px;
        font-size:12px;
        font-family:LFT Etica, sans-serif;
        a{
            cursor:pointer
        }
    }
    @media(max-width: 400px),(min-device-width: 320px)and (max-device-width: 480px){
        .email-correction form .form-columns-2 .hs-form-field,.email-correction form .form-columns-3 .hs-form-field,.email-validation form .form-columns-2 .hs-form-field,.email-validation form .form-columns-3 .hs-form-field{
            float:none;
            width:100%
        }
        .email-correction form .form-columns-2 .hs-form-field .hs-input,.email-correction form .form-columns-3 .hs-form-field .hs-input,.email-validation form .form-columns-2 .hs-form-field .hs-input,.email-validation form .form-columns-3 .hs-form-field .hs-input{
            width:90%
        }
        .email-correction form .form-columns-2 .hs-form-field input[type=checkbox],.email-correction form .form-columns-2 .hs-form-field input[type=radio],.email-correction form .form-columns-3 .hs-form-field input[type=checkbox],.email-correction form .form-columns-3 .hs-form-field input[type=radio],.email-validation form .form-columns-2 .hs-form-field input[type=checkbox],.email-validation form .form-columns-2 .hs-form-field input[type=radio],.email-validation form .form-columns-3 .hs-form-field input[type=checkbox],.email-validation form .form-columns-3 .hs-form-field input[type=radio]{
            width:24px
        }
    }
    .hs-button,.hs-form-field input[type=text],.hs-form-field input[type=email],.hs-form-field input[type=phone],.hs-form-field input[type=number],.hs-form-field input[type=tel],.hs-form-field input[type=date],.hs-form-field textarea{
        -webkit-appearance:none;
        -moz-appearance:none
    }
    .hs-default-font-element,.hs-main-font-element{
        font-family:LFT Etica, sans-serif;
        line-height:normal
    }
    #hsForm_3ebc1108-a785-4ed7-8c2f-d384347274d5 .hs_error_rollup .hs-error-msgs label.hs-main-font-element, 
    #hsForm_ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 .hs_error_rollup .hs-error-msgs label.hs-main-font-element {
        position: relative;
    }
    
    #hsForm_3ebc1108-a785-4ed7-8c2f-d384347274d5 .hs_error_rollup .hs-error-msgs label.hs-main-font-element:after, #hsForm_ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 .hs_error_rollup .hs-error-msgs label.hs-main-font-element:after  {
        content: "*champ obligatoire";
        display: block;
        left: 0;
        top: 0;
        font-family:LFT Etica, sans-serif;
        position: absolute;
        background-color:#FFF2F9;
        width: 100%;
        height: 100%;
    }
    #hsForm_ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 .hs_error_rollup .hs-error-msgs label.hs-main-font-element:after  {
        background-color: #ffffff;
    }
    
    
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051.hs-form {
         width: 100% 
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-submit > .actions {
         text-align: left;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button:hover, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button:hover:not(.inactive), .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button:focus, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button:active, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button:active:not(.inactive):not(.link) {
         background: #ce6ba4;
         border-color: #ce6ba4;
         color: #ffffff;
         font-size: 12px;
         line-height: 12px;
         font-family:LFT Etica, sans-serif;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 legend.hs-field-desc {
         font-family:LFT Etica, sans-serif;
         color: #ce6ba4;
         font-size: 11px;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-form-field label:not(.hs-error-msg) {
         font-family:LFT Etica, sans-serif;
         font-size: 14px;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-form-field label:not(.hs-error-msg) {
         color: #2E2E31;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .legal-consent-container .hs-richtext, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .legal-consent-container label:not(.hs-error-msg) {
         font-family:LFT Etica, sans-serif;
         color: #2E2E31;
         font-size: 14px;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-main-font-element, .submitted-message.hs-main-font-element{
         font-family:LFT Etica, sans-serif;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051.submitted-message {
         font-size: 16px;
         color: #2E2E31;
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 a:link, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 a:active {
         color: #0000EE 
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 a:visited, .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 a:hover {
         color: #551A8B 
    }
     .fn-date-picker.pika-single{
        z-index:9999;
        display:block;
        position:relative;
        color:#2E2E31;
        background:#fff;
        border:1px solid #ccc;
        border-bottom-color:#bbb;
        font-family:LFT Etica, sans-serif;
        *zoom:1
    }
    .fn-date-picker.pika-single.is-hidden{
        display:none
    }
    .fn-date-picker.pika-single.is-bound{
        position:absolute;
        box-shadow:0 5px 15px -5px rgba(0,0,0,.5)
    }
    .fn-date-picker.pika-single:after,.fn-date-picker.pika-single:before{
        content:" ";
        display:table
    }
    .fn-date-picker.pika-single:after{
        clear:both
    }
    .fn-date-picker .pika-lendar{
        float:left;
        width:240px;
        margin:8px
    }
    .fn-date-picker .pika-title{
        position:relative;
        text-align:center
    }
    .fn-date-picker .pika-title select{
        cursor:pointer;
        position:absolute;
        z-index:9998;
        margin:0;
        left:0;
        top:5px;
        filter:alpha(opacity=0);
        opacity:0
    }
    .fn-date-picker .pika-label{
        display:inline-block;
        *display:inline;
        position:relative;
        z-index:9999;
        overflow:hidden;
        margin:0;
        padding:5px 3px;
        font-size:14px;
        line-height:20px;
        font-weight:700;
        background-color:#fff
    }
    .fn-date-picker .pika-next,.fn-date-picker .pika-prev{
        display:block;
        cursor:pointer;
        position:relative;
        outline:none;
        border:0;
        padding:0;
        width:20px;
        height:30px;
        text-indent:20px;
        white-space:nowrap;
        overflow:hidden;
        background-color:transparent;
        background-position:50%;
        background-repeat:no-repeat;
        background-size:75% 75%;
        opacity:.5;
        *position:absolute;
        *top:0
    }
    .fn-date-picker .pika-next:hover,.fn-date-picker .pika-prev:hover{
        opacity:1
    }
    .fn-date-picker .pika-next.is-disabled,.fn-date-picker .pika-prev.is-disabled{
        cursor:default;
        opacity:.2
    }
    .fn-date-picker .is-rtl .pika-next,.fn-date-picker .pika-prev{
        float:left;
        background-image:url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
        *left:0
    }
    .fn-date-picker .is-rtl .pika-prev,.fn-date-picker .pika-next{
        float:right;
        background-image:url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
        *right:0
    }
    .fn-date-picker .pika-select{
        display:inline-block;
        *display:inline
    }
    .fn-date-picker .pika-table{
        width:100%;
        border-collapse:collapse;
        border-spacing:0;
        border:0
    }
    .fn-date-picker .pika-table td,.fn-date-picker .pika-table th{
        width:14.285714285714286%;
        padding:0
    }
    .fn-date-picker .pika-table th{
        color:#999;
        font-size:12px;
        line-height:25px;
        font-weight:700;
        text-align:center
    }
    .fn-date-picker .pika-table abbr{
        border-bottom:none;
        cursor:help
    }
    .fn-date-picker .pika-button{
        cursor:pointer;
        display:block;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        outline:none;
        border:0;
        margin:0;
        width:100%;
        padding:5px;
        color:#666;
        font-size:12px;
        line-height:15px;
        text-align:right;
        background:#f5f5f5
    }
    .fn-date-picker .pika-button:hover{
        color:#fff!important;
        background:#ff8000!important;
        box-shadow:none!important;
        border-radius:3px!important
    }
    .fn-date-picker .is-today .pika-button{
        color:#3af;
        font-weight:700
    }
    .fn-date-picker .is-selected .pika-button{
        color:#fff;
        font-weight:700;
        background:#3af;
        box-shadow:inset 0 1px 3px #178fe5;
        border-radius:3px
    }
    .fn-date-picker .is-disabled .pika-button{
        pointer-events:none;
        cursor:default;
        color:#999;
        opacity:.3
    }
    .fn-date-picker .pika-week{
        font-size:11px;
        color:#999
    }
     .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list li{
        vertical-align:top;
        display:inline-block;
        word-wrap:break-word;
        padding-right:16px
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list li:after{
        clear:both
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list li input{
        float:left
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list.inline-list-2 li{
        width:50%
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list.inline-list-2 li:nth-child(2n){
        padding-right:0
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list.inline-list-3 li{
        width:33%
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .inputs-list.inline-list.inline-list-3 li:nth-child(3n){
        width:34%;
        padding-right:0
    }
    .hs-fieldtype-intl-phone.hs-input{
        padding:0;
        background:none;
        border:none;
        height:auto
    }
    .hs-fieldtype-intl-phone.hs-input:after{
        clear:both;
        content:" ";
        display:table
    }
    .hs-fieldtype-intl-phone.hs-input .hs-input{
        margin-bottom:0
    }
    .hs-fieldtype-intl-phone.hs-input input{
        width:68%!important;
        float:right
    }
    .hs-fieldtype-intl-phone.hs-input select{
        float:left;
        width:30%!important
    }
    
    #phone_ext-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 {
        display: none
    }
    #phone-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 {
        float: none;
        width: 100% !important
    }
    .hs_phone .hs-field-desc {
        font-size: 12px
    }
    #hsForm_ea7d0ab2-2840-4377-a3f2-f3ae16ebd928 .hs-field-desc {
        display: none !important
    }
    @media (max-device-width:480px) and (min-device-width:320px),(max-width:400px){
        .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051>.hs-phone>.input>.hs-fieldtype-intl-phone.hs-input>input.hs-input{
            width:68%!important
        }
        .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051>.hs-phone>.input>.hs-fieldtype-intl-phone.hs-input>select.hs-input{
            width:30%!important
        }
        .hs-custom-style .hs-input:not([type=checkbox]):not([type=radio]),.hs-custom-style fieldset{
            margin-right:0!important;
            width:100%!important
        }
        form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field,form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field{
            float:none;
            width:100%
        }
        form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field .hs-input,form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field .hs-input{
            width:95%
        }
        form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field input[type=checkbox],form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field input[type=radio],form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field input[type=checkbox],form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field input[type=radio]{
            width:auto
        }
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-button{
        white-space:pre-wrap
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-richtext{
        word-break:break-word
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset{
        border:0;
        padding:0;
        margin:0;
        max-width:500px
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-1 .hs-input{
        width:95%
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-1 .input{
        margin-right:8px
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-1 input[type=checkbox],.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-1 input[type=radio]{
        width:auto
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-2 .hs-form-field{
        width:50%;
        float:left
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-2 .input{
        margin-right:8px
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-3 .hs-form-field{
        width:32.7%;
        float:left
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 fieldset.form-columns-3 .input{
        margin-right:8px
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 label.hs-hidden{
        visibility:hidden
    }
    .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 .hs-field-desc{
        width:100%
    }
    .hs-custom-style .hs-input,.hs-custom-style fieldset{
        max-width:100%
    }
    .hs-custom-style>div.form-columns-3 .hs-form-field,.hs-custom-style fieldset.form-columns-3 .hs-form-field{
        width:33.3%
    }
    .hs-custom-style>div>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]),.hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]){
        width:100%;
        max-width:100%
    }
    .hs-custom-style>div input:not([type=image]):not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]):not([type=file]),.hs-custom-style fieldset input:not([type=image]):not([type=submit]):not([type=button]):not([type=radio]):not([type=checkbox]):not([type=file]){
        box-sizing:border-box;
        padding:0 15px;
        min-height:27px
    }
    .hs-custom-style>div textarea,.hs-custom-style fieldset textarea{
        padding:10px 15px
    }
    .hs-custom-style .hs-dependent-field>div .hs-input:not([type=checkbox]):not([type=radio]){
        width:100%
    }
    .legal-consent-container .field.hs-form-field{
        margin-bottom:8px
    }
    .legal-consent-container .hs-field-desc.checkbox-desc{
        margin:-12px 0 0 21px
    }
    .legal-consent-container .hs-form-booleancheckbox-display input{
        float:left
    }
    .legal-consent-container .hs-form-booleancheckbox-display>span{
        display:block;
    }
    .legal-consent-container .hs-form-booleancheckbox-display p{
        margin:0;
        display:inline
    }
    .legal-consent-container .hs-error-msgs label{
        color:#f2545b
    }
    .legal-consent-container~.hs_recaptcha{
        margin-top:18px
    }
    .cookie-reset-container{
        font-size:14px;
        margin-bottom:10px;
        text-align:right
    }
    #hs-outer-captcha-target,#hs-outer-captcha-target *{
        display:none;
        height:0;
        width:0
    }
    .hubspot-link__container{
        font-size:14px;
        padding-bottom:40px;
        position:relative;
        color:#9fa0a2;
        font-family:LFT Etica, sans-serif;
    }
    .hubspot-link-text{
        color:#00a4bd;
        font-weight:400
    }
    .hubspot-link__container.sproket{
        color:#9fa0a2
    }
    .hubspot-link{
        color:#9fa0a2
    }
    .hubspot-link,.hubspot-link:hover{
        text-decoration:none
    }
    .hubspot-link:hover .hubspot-link-text{
        text-decoration:underline
    }
    .hubspot-link__icon{
        margin-bottom:-1px;
        margin-right:5px
    }
    .hubspot-link__container.sproket .hubspot-link__icon{
        width:30px;
        margin-right:0;
        float:left;
        margin-top:-9px;
        margin-left:-5px
    }
    
    #label-motivations_candidates-3ebc1108-a785-4ed7-8c2f-d384347274d5, #label-temporalite-3ebc1108-a785-4ed7-8c2f-d384347274d5, #label-hs_lead_status-f09d55c9-54f3-4851-adf5-a6c08c2cdd4c {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 700;
        font-style: italic;
        color: #CE6BA4
    }
}