.front.bar{
    font-family: "Avenir Next", sans-serif;
    overflow-x: hidden;
    .mobile{
        display: none;
        @media (max-width:767px) {
            display: block;
        }
    }
    .reverse{ 
        @media (max-width:767px) {
            flex-direction: column-reverse;
        }
    }
    .desktop{
        @media (max-width:767px) {
            display: none;
        }
    }
    header{
        @media (max-width:767px) {
            padding-bottom: 0;
        }
        img{
            max-width: 250px;
            width: 100%;
            height: auto;
        }
        .ttr{
            letter-spacing: 2px;
            line-height: 52px;
            @media (max-width:767px) {
                line-height: 24px;
                letter-spacing: 1px;
            }
            span{
                color:#fff;
                background-color: #6682a3;
                padding: 0 .5rem;
                @media (max-width:767px) {
                    display: block;
                    width: fit-content;
                    margin: auto;
                }
            }
        }
        .para{
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
    section{
        .bloc-bar{
            padding: 3rem 0;
            position: relative;
            @media (max-width:767px) {
                padding: 1.5rem 0;
            }
            &::before{
                content: "";
                position:absolute;
                width: 1920px;
                height: 550px;
                background-color: #f7f5f2;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width:991px) {
                    height: 480px;
                }
                @media (max-width:767px) {
                    height: 210px;
                    top: 80px;
                }
            }
            .col-md-7,.col-md-5{
                position: relative;
            }
            .col-md-5{
                @media (max-width:767px) {
                    padding: 0;
                    img{
                        display: inline-block;
                        &:first-child{
                            width: 60%;
                        }
                        &:last-child{
                            width: 40%;
                            padding: 0 15px;
                        }
                    }
                }
            }
            .jean-details{
                position: relative;
                display: flex;
                align-items: center;
                .pic{
                    padding: 0 1rem;
                    img{
                        width: 180px;
                    }
                }
                .details{
                    max-width: 365px;
                    width: 100%;
                    .ttr{
                        text-transform: uppercase;
                        margin-bottom: 4rem;
                        @media (max-width:991px) {
                            font-size: 36px;
                            margin-bottom: 3rem;
                        }
                        @media (max-width:767px) {
                            margin:2rem 0 1rem;
                        }
                        span{
                            text-transform: capitalize;
                            @media (max-width:991px) {
                                font-size: 26px;
                            }
                        }
                    }
                    .content{
                        min-height: 450px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-bottom: 120px;
                        @media (max-width:991px) {
                            min-height: 380px;
                            margin-bottom: 100px;
                        }
                        @media (max-width:767px) {
                            min-height:unset;
                            margin-bottom: 0;
                            p.composition,p.ref,ul,p.short-description{
                                display: none;
                            }
                        }
                        p.short-description{
                            @media (max-width:767px) {
                                order:2;
                            }
                        }
                        ul{
                            margin: 1.5rem 0;
                            padding: 0;
                            list-style: none;
                            @media (max-width:991px) {
                                margin:1rem 0
                            }
                            @media (max-width:767px) {
                                order:3;
                            }
                            li{
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                        }
                        p.composition,p.ref{
                            margin-bottom: 1.5rem;
                            @media (max-width:991px) {
                                margin-bottom:1rem;
                            }
                        }
                        p.composition{
                            @media (max-width:767px) {
                                order:4;
                            }
                        }
                        p.ref{
                            width: fit-content;
                            min-width: 250px;
                            letter-spacing: 1px;
                            text-align: center;
                            padding: 4px 16px;
                            @media (max-width:767px) {
                                order:5;
                            }
                        }
                        .button{
                            display: block;
                            width: fit-content;
                            text-decoration: none;
                            padding-bottom: 5px;
                            text-transform: uppercase;
                            letter-spacing: 3px;
                            position: relative;
                            &:after{
                                content:"";
                                height: 2px;
                                width: 0;
                                display: block;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transition: all .5s ease;
                            }
                            &:hover{
                                &:after{
                                    width: 100%;
                                }
                            }
                            @media (max-width:767px) {
                                order:1;
                                margin-bottom: 1rem;
                            }
                        }
                        .btn{
                            margin-bottom: 1.5rem;
                            order: 6;
                            &:focus,&:active{
                                border: none;
                                outline: 0;
                            }
                            img{display: block;margin: auto;transition: all .5s ease;}
                        }
                        &.open{
                            p.composition,p.ref,ul,p.short-description{
                                display: block;
                                animation: fadeIn ease 2s;
                            }
                            .btn img{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
    footer{
        padding: 3rem 0 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width:767px) {
            flex-direction: column;
            align-items: center;
            padding: 2rem 15px 1rem;
        }
        .logo{
            margin-right: -50px;
        }
        p{
            font-style: italic;
        }
        .tortue{
            width: 25px;
            margin-left: 5px;
            @media (max-width:767px) {
                display: block;
                margin: 1rem auto 0;
            }
        }
    }
}
@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}
@-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}
@-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}
@-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
} 
@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}